import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";

const MarriageFormStep2 = ({
  formik,
  basicDetails,
  currentMarriage,
  outsider,
}) => {
  const [baptismName, setBaptismName] = useState(true);

  useEffect(() => {
    if (baptismName) {
      if (basicDetails?.baptismName) {
        formik.setFieldValue("groomFirstName", basicDetails?.baptismName);
        formik.setFieldValue("groomMiddleName", "");
        formik.setFieldValue("groomLastName", "");
      }
    } else {
      formik.setFieldValue("groomFirstName", basicDetails?.firstName);
      formik.setFieldValue("groomMiddleName", basicDetails?.middleName);
      formik.setFieldValue("groomLastName", basicDetails?.lastName);
    }
  }, [baptismName]);

  useEffect(() => {
    if (basicDetails?.baptismName === currentMarriage?.firstName) {
      setBaptismName(true);
    } else {
      setBaptismName(false);
    }
  }, []);

  return (
    <Row className="g-3">
      <Col sm={!baptismName ? 4 : 12}>
        <TextInput
          label={baptismName ? "Groom's Baptism Name" : "Groom's First Name"}
          placeholder={"e.g. George"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomFirstName"}
          value={formik.values.groomFirstName}
          error={formik.errors.groomFirstName}
          touched={formik.touched.groomFirstName}
          disabled={basicDetails?.firstName ? true : false}
        />
        {basicDetails?.baptismName && (
          <Form.Check
            type="switch"
            className="mt-1"
            checked={baptismName}
            label="Use Baptism Name"
            onChange={(e) => {
              setBaptismName(e.target.checked);
            }}
          />
        )}
      </Col>
      {!baptismName && (
        <Col sm={4}>
          <TextInput
            label={"Groom's Middle Name"}
            setFieldValue={formik.setFieldValue}
            keyword={"groomMiddleName"}
            value={formik.values.groomMiddleName}
            error={formik.errors.groomMiddleName}
            touched={formik.touched.groomMiddleName}
            disabled={true}
          />
        </Col>
      )}
      {!baptismName && (
        <Col sm={4}>
          <TextInput
            label={"Groom's Last Name"}
            placeholder={"e.g. Watts"}
            setFieldValue={formik.setFieldValue}
            keyword={"groomLastName"}
            value={formik.values.groomLastName}
            error={formik.errors.groomLastName}
            touched={formik.touched.groomLastName}
            disabled={true}
          />
        </Col>
      )}
      <Col sm={6}>
        <TextInput
          label={"Groom's Father's Name"}
          placeholder={"e.g. James Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomFatherName"}
          value={formik.values.groomFatherName}
          error={formik.errors.groomFatherName}
          touched={formik.touched.groomFatherName}
          disabled={outsider ? false : true}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Groom's Mother's Name"}
          placeholder={"e.g. Julia Watts"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomMotherName"}
          value={formik.values.groomMotherName}
          error={formik.errors.groomMotherName}
          touched={formik.touched.groomMotherName}
          disabled={outsider ? false : true}
        />
      </Col>
      <Col sm={12}>
        <DateInput
          label={"Groom's Date Of Birth"}
          placeholder={"e.g. DD-MM-YYYY"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomDob"}
          value={formik.values.groomDob}
          error={formik.errors.groomDob}
          touched={formik.touched.groomDob}
          disabled={basicDetails?.dateOfBirth ? true : false}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Groom's Nationality"}
          placeholder={"e.g. Indian"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomNationality"}
          value={formik.values.groomNationality}
          error={formik.errors.groomNationality}
          touched={formik.touched.groomNationality}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Groom's Domicile"}
          placeholder={"e.g. Delhi"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomDomicile"}
          value={formik.values.groomDomicile}
          error={formik.errors.groomDomicile}
          touched={formik.touched.groomDomicile}
        />
      </Col>
      <Col sm={4}>
        <TextInput
          label={"Groom's Profession"}
          placeholder={"e.g. Service"}
          setFieldValue={formik.setFieldValue}
          keyword={"groomProfession"}
          value={formik.values.groomProfession}
          error={formik.errors.groomProfession}
          touched={formik.touched.groomProfession}
          disabled={outsider ? false : true}
        />
      </Col>
      <Col sm={12}>
        <SelectInput
          label={"Groom's Marital Status"}
          placeholder={"e.g. SINGLE"}
          selectOptions={[
            { value: "SINGLE", label: "SINGLE" },
            { value: "DIVORCE", label: "DIVORCE" },
            { value: "WIDOWER", label: "WIDOWER" },
          ]}
          setFieldValue={formik.setFieldValue}
          keyword={"groomMarriageStatus"}
          value={formik.values.groomMarriageStatus}
          error={formik.errors.groomMarriageStatus}
          touched={formik.touched.groomMarriageStatus}
        />
        <p className="text-muted m-0">Marital Status of groom up until now.</p>
      </Col>
      {!(
        formik.values.groomMarriageStatus === "SINGLE" ||
        formik.values.groomMarriageStatus === ""
      ) && (
        <Col sm={12}>
          <TextInput
            label={"Groom's Previous Wife Name (Optional)"}
            placeholder={"e.g. Riley"}
            setFieldValue={formik.setFieldValue}
            keyword={"previousWifeName"}
            value={formik.values.previousWifeName}
            error={formik.errors.previousWifeName}
            touched={formik.touched.previousWifeName}
          />
        </Col>
      )}
    </Row>
  );
};

export default MarriageFormStep2;
