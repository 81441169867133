import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import DateInput from "../../components/form/DateInput";
import SelectInput from "../../components/form/SelectInput";

const BaptismFormStep1 = ({ formik, basicDetails, outsider }) => {
  const [baptismName, setBaptismName] = useState(false);

  useEffect(() => {
    if (baptismName) {
      if (basicDetails?.baptismName) {
        formik.setFieldValue("firstName", basicDetails?.baptismName);
        formik.setFieldValue("middleName", "");
        formik.setFieldValue("lastName", "");
      }
    } else {
      formik.setFieldValue("firstName", basicDetails?.firstName);
      formik.setFieldValue("middleName", basicDetails?.middleName);
      formik.setFieldValue("lastName", basicDetails?.lastName);
    }
  }, [baptismName]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Row className="g-3">
      <Col sm={!baptismName ? 4 : 12}>
        <TextInput
          label={baptismName ? "Baptism Name" : "First Name"}
          placeholder={"Enter First Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"firstName"}
          value={formik.values.firstName}
          error={formik.errors.firstName}
          touched={formik.touched.firstName}
          disabled={basicDetails?.firstName ? true : false}
        />
        {basicDetails?.baptismName && (
          <Form.Check
            type="switch"
            className="mt-1"
            checked={baptismName}
            label="Use Baptism Name"
            onChange={(e) => {
              setBaptismName(e.target.checked);
            }}
          />
        )}
      </Col>
      {!baptismName && (
        <Col sm={4}>
          <TextInput
            label={"Middle Name"}
            placeholder={"Enter Middle Name"}
            setFieldValue={formik.setFieldValue}
            keyword={"middleName"}
            value={formik.values.middleName}
            error={formik.errors.middleName}
            touched={formik.touched.middleName}
            disabled={outsider ? false : true}
          />
        </Col>
      )}
      {!baptismName && (
        <Col sm={4}>
          <TextInput
            label={"Last Name"}
            placeholder={"Enter Last Name"}
            setFieldValue={formik.setFieldValue}
            keyword={"lastName"}
            value={formik.values.lastName}
            error={formik.errors.lastName}
            touched={formik.touched.lastName}
            disabled={outsider ? false : true}
          />
        </Col>
      )}
      <Col sm={6}>
        <TextInput
          label={"Father's Name"}
          placeholder={"Enter Father's Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"fatherName"}
          value={formik.values.fatherName}
          error={formik.errors.fatherName}
          touched={formik.touched.fatherName}
          // disabled={basicDetails?.fatherName ? true : false}
          disabled={!outsider}
        />
      </Col>
      <Col sm={6}>
        <TextInput
          label={"Mother's Name"}
          placeholder={"Enter Mother's Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"motherName"}
          value={formik.values.motherName}
          error={formik.errors.motherName}
          touched={formik.touched.motherName}
          // disabled={basicDetails?.motherName ? true : false}
          disabled={!outsider}
        />
      </Col>
      <Col sm={6}>
        <DateInput
          label={"Date Of Birth"}
          setFieldValue={formik.setFieldValue}
          keyword={"dob"}
          value={formik.values.dob}
          error={formik.errors.dob}
          touched={formik.touched.dob}
          disabled={basicDetails?.dateOfBirth ? true : false}
        />
      </Col>
      <Col sm={6}>
        <SelectInput
          label={"Gender"}
          selectOptions={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          setFieldValue={formik.setFieldValue}
          keyword={"gender"}
          value={formik.values.gender}
          error={formik.errors.gender}
          touched={formik.touched.gender}
          disabled={basicDetails?.gender ? true : false}
        />
      </Col>
      <Col sm={12}>
        <DateInput
          label={"Date Of Baptism"}
          setFieldValue={formik.setFieldValue}
          keyword={"dateOfBaptism"}
          value={formik.values.dateOfBaptism}
          error={formik.errors.dateOfBaptism}
          touched={formik.touched.dateOfBaptism}
        />
      </Col>

      <Col className="mt-2" sm={12}>
        <Form.Check
          type="checkbox"
          label="Not sure if baptism date is correct"
          onChange={(e) => {
            formik.setFieldValue("baptismDateNotSure", e.target.checked);
          }}
          checked={formik.values.baptismDateNotSure}
        />
      </Col>

      <Col sm={12}>
        <SelectInput
          label={"Rite"}
          selectOptions={[
            { label: "Latin", value: "Latin" },
            { label: "SyroMalabar", value: "SyroMalabar" },
            { label: "SyroMalankara", value: "SyroMalankara" },
          ]}
          setFieldValue={formik.setFieldValue}
          keyword={"rite"}
          value={formik.values.rite}
          error={formik.errors.rite}
          touched={formik.touched.rite}
        />
      </Col>
    </Row>
  );
};

export default BaptismFormStep1;
