import React, { useState } from "react";
import { Button, Card, Col, Row, Badge, Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import ChooseHolyConfirmationTypeModal from "../../modals/holy-confirmation/ChooseHolyConfirmationTypeModal";
import AddExternalHolyConfirmationDetailsModal from "../../modals/holy-confirmation/AddExternalHolyConfirmationDetailsModal";
import AddHolyConfirmationDetailsModal from "../../modals/holy-confirmation/AddHolyConfirmationDetailsModal";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import ConfirmationInfoOffset from "../../offset-canvas/holy-confirmation/ConfirmationInfoOffset";
import { useSelector } from "react-redux";
import HolyConfirmationLinkCertificateModal from "../../modals/holy-confirmation/HolyConfirmationLinkCertificateModal";
import GeneratedHolyConfirmationCertmodal from "../../modals/holy-confirmation/GeneratedHolyConfirmationCertmodal";

const ConfirmationDetails = ({
  baptismDetails,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  commonAPIRequest,
  holyConfirmationDetails,
  holyCommunionDetails,
  contactDetails,
  familyDetails,
  deathDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [
    holyConfirmationDetailModalVisible,
    setHolyConfirmationDetailModalVisible,
  ] = useState(false);

  const [extHolyConfirmationModalVisible, setExtHolyConfirmationModalVisible] =
    useState(false);

  const [holyConfirmationmModalVisible, setHolyConfirmationModalVisible] =
    useState(false);
  const [certPdf, setCertPdf] = useState();
  const [
    holyConfirmationOffsetModalVisible,
    setHolyConfirmationOffsetModalVisible,
  ] = useState(false);
  const [currentHolyConfirmation, setCurrentHolyConfirmation] = useState();
  const { churchDetails } = useSelector((state) => state?.church);
  const [confirmationLinkCertModal, setConfirmationLinkCertModal] =
    useState(false);
  const [loading2, setLoading2] = useState(false);
  const [generateConfirmationCertModal, setGenerateConfirmationCertModal] =
    useState(false);

  // call generate pdf certificate api for confirmation start

  const callGetHolyConfirmationCertificateApi = () => {
    let serviceParams = {
      api: `${API.GENERATE_HOLY_CONFIRMATION_CERTIFICATE}?confirmationId=${holyConfirmationDetails?.confirmationId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCertPdf(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call generate pdf certificate api for confirmation end

  // call generate pdf certificate api for confirmation end

  const callGetHolyConfirmationDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_DETAILS}?confirmationId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          setCurrentHolyConfirmation(result?.payload);
          setConfirmationLinkCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetHolyConfirmationDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_DETAILS}?confirmationId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCurrentHolyConfirmation(result?.payload);
          setGenerateConfirmationCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      {holyConfirmationDetails ? (
        <Card className="card-post mt-4 ">
          <Card.Header>
            <h3 className="fw-bold text-primary">
              Holy Confirmation Information
            </h3>
            <Button onClick={() => setHolyConfirmationOffsetModalVisible(true)}>
              <i className="ri-information-line"></i>
            </Button>
          </Card.Header>
          <hr className="hr m-0" />
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Holy Confirmation Done?</strong>
                      </p>
                      <p className="mb-0">Yes</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Date Of Holy Confirmation</strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-start">
                        <p className="mb-0">
                          {new Date(
                            holyConfirmationDetails?.confirmationDate
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                        {!holyConfirmationDetails?.confirmationDateAccurate && (
                          <Badge
                            style={{ padding: 5 }}
                            className="ms-2 fw-bold"
                            bg="warning"
                          >
                            Uncertain
                          </Badge>
                        )}
                      </div>
                    </div>
                  </Col>
                  {!holyConfirmationDetails?.extConfirmation && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong>Holy Confirmation Certificate ID</strong>
                        </p>
                        <p className="mb-0">
                          {holyConfirmationDetails?.referenceNum}
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>External Holy Confirmation</strong>
                      </p>
                      <p className="mb-0">
                        {holyConfirmationDetails?.extConfirmation
                          ? holyConfirmationDetails?.extConfirmationChurch ||
                            holyConfirmationDetails?.extConfirmationCity
                            ? "Yes"
                            : "Not Sure"
                          : "No"}
                      </p>
                    </div>
                  </Col>
                  {holyConfirmationDetails?.extConfirmation &&
                    holyConfirmationDetails?.extConfirmationChurch && (
                      <Col sm={4}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>External Holy Confirmation Church</strong>
                          </p>
                          <p className="mb-0">
                            {holyConfirmationDetails?.extConfirmationChurch}
                          </p>
                        </div>
                      </Col>
                    )}
                  {holyConfirmationDetails?.extConfirmation &&
                    holyConfirmationDetails?.extConfirmationCity && (
                      <Col sm={4}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>External Holy Confirmation City</strong>
                          </p>
                          <p className="mb-0">
                            {holyConfirmationDetails?.extConfirmationCity}
                          </p>
                        </div>
                      </Col>
                    )}
                </Row>
                {!holyConfirmationDetails?.extConfirmation && (
                  <div>
                    <hr className="hr" />
                    <div className="text-center m-4">
                      <Card.Title className="text-dark">
                        Generate Holy Confirmation Certificate for{" "}
                        {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                        {basicDetails?.lastName}
                      </Card.Title>
                      <Card.Text>
                        Please click on Generate for generating holy
                        confirmation certificate copy for{" "}
                        <span className="fw-bold text-primary">
                          {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                          {basicDetails?.lastName}
                        </span>
                      </Card.Text>
                      <div>
                        <Button
                          disabled={holyConfirmationDetails?.extConfirmation}
                          variant="primary"
                          onClick={() => {
                            callGetHolyConfirmationDetailsForLink(
                              holyConfirmationDetails?.confirmationId
                            );
                          }}
                        >
                          {loading2 ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                                size="sm"
                              />
                              Generating...
                            </span>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center">
                              <i className="ri-download-2-fill me-2"></i>
                              Generate Link
                            </div>
                          )}
                        </Button>

                        {/* <Button
                          variant="primary"
                          className="ms-2"
                          onClick={() => {
                            callGetHolyConfirmationDetailsForPreview(
                              holyConfirmationDetails?.confirmationId
                            );
                          }}
                        >
                          {loading ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                              />
                              Generating...
                            </span>
                          ) : (
                            <i className="ri-eye-line"></i>
                          )}
                        </Button> */}
                      </div>
                      {/* {loading ? (
                        <div className="justify-content-center align-items-center d-flex">
                          <Lottie
                            style={{ width: 100, height: 100 }}
                            animationData={loadingAnimation}
                            loop={true}
                          />
                        </div>
                      ) : certPdf?.certName ? (
                        <Button
                          onClick={() => {
                            let pdfWindow = window.open("");
                            pdfWindow.document.write(
                              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                encodeURI(certPdf.base64) +
                                "'></iframe>"
                            );
                          }}
                          variant="success"
                        >
                          Print
                        </Button>
                      ) : (
                        <Button
                          onClick={callGetHolyConfirmationCertificateApi}
                          variant="primary"
                        >
                          Generate
                        </Button>
                      )} */}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
          {
            <Card.Footer className="bg-light text-primary text-end">
              Last Updated by{" "}
              <span className="fw-bold">
                {holyConfirmationDetails?.updatedBy}
              </span>{" "}
              on
              <span className="fw-bold">
                {" "}
                {new Date(
                  holyConfirmationDetails?.updateTmStmp
                ).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </Card.Footer>
          }
        </Card>
      ) : (
        <Card.Body className="text-center m-4">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 150, height: 150 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
          </div>
          <Card.Title className="text-dark">
            No Holy Confirmation Details Found
          </Card.Title>
          <Card.Text>
            Please add holy confirmation details for{" "}
            <span className="fw-bold text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </span>{" "}
            and generate certificate.
            <br />
            <span className="text-danger fw-bold">
              NOTE: *Holy Communion is required for adding holy confirmation
              details.
            </span>
          </Card.Text>
          <Button
            variant="primary"
            disabled={
              holyCommunionDetails?.holyCommunionId && !deathDetails
                ? false
                : true
            }
            onClick={() => setHolyConfirmationDetailModalVisible(true)}
          >
            Add
          </Button>
        </Card.Body>
      )}
      <ChooseHolyConfirmationTypeModal
        show={holyConfirmationDetailModalVisible}
        setShow={setHolyConfirmationDetailModalVisible}
        basicDetails={basicDetails}
        setExtHolyConfirmationModalVisible={setExtHolyConfirmationModalVisible}
        setHolyConfirmationModalVisible={setHolyConfirmationModalVisible}
      />
      <AddExternalHolyConfirmationDetailsModal
        memberId={memberId}
        holyCommunionDetails={holyCommunionDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
        basicDetails={basicDetails}
        show={extHolyConfirmationModalVisible}
        setShow={setExtHolyConfirmationModalVisible}
        baptismDetails={baptismDetails}
      />
      <AddHolyConfirmationDetailsModal
        show={holyConfirmationmModalVisible}
        setShow={setHolyConfirmationModalVisible}
        memberId={memberId}
        holyCommunionDetails={holyCommunionDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
        basicDetails={basicDetails}
        baptismDetails={baptismDetails}
        contactDetails={contactDetails}
        familyDetails={familyDetails}
      />
      <ConfirmationInfoOffset
        show={holyConfirmationOffsetModalVisible}
        setShow={setHolyConfirmationOffsetModalVisible}
        confirmationId={holyConfirmationDetails?.confirmationId}
        basicDetails={basicDetails}
        memberId={memberId}
        holyCommunionDetails={holyCommunionDetails}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />

      <HolyConfirmationLinkCertificateModal
        show={confirmationLinkCertModal}
        setShow={setConfirmationLinkCertModal}
        churchDetails={churchDetails}
        currentHolyConfirmation={currentHolyConfirmation}
      />

      <GeneratedHolyConfirmationCertmodal
        show={generateConfirmationCertModal}
        setShow={setGenerateConfirmationCertModal}
        churchDetails={churchDetails}
        currentHolyConfirmation={currentHolyConfirmation}
      />
    </div>
  );
};

export default withAPIRequest(ConfirmationDetails);
