import React, { useEffect, useState } from "react";
import { Accordion, Badge, Button, Col, Row, Table } from "react-bootstrap";

import img29 from "../../assets/img/img29.jpg";
import img30 from "../../assets/img/img30.jpg";
import img31 from "../../assets/img/img31.jpg";
import img32 from "../../assets/img/img32.jpg";
import img33 from "../../assets/img/img33.jpg";
import { Link } from "react-router-dom";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { LoadingAction } from "../../store/slices/LoadingSlice";
import { useDispatch } from "react-redux";
import AddParishUsersModal from "../../modals/parishes/AddParishUsersModal";
import Swal from "sweetalert2";
import AddNonPriestUserModal from "../../modals/parishes/AddNonPriestUserModal";

const ParishUsers = ({ selectedTab, commonAPIRequest, churchId }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [addParishUser, setAddParishUser] = useState(false);

  const callGetAllParishUsers = () => {
    let serviceParams = {
      api: `${API.GET_ALL_USERS_RESOURCE}?pgNum=1&pgSize=100&churchId=${churchId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    dispatch(LoadingAction.startTranslucentLoader());
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        dispatch(LoadingAction.endTranslucentLoader());
        try {
          console.log("users", result.payload);
          setUsers(result?.payload?.userList);
          // setParishes(result?.payload?.churchList);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  const deleteParishUser = (id) => {
    let serviceParams = {
      api: `${API.DELETE_USER_INFO}?userId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        try {
          Swal.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });
          callGetAllParishUsers();
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedTab === 2) {
      callGetAllParishUsers();
    }
  }, [selectedTab]);

  return (
    <Row>
      <div className="d-flex justify-content-end mb-3">
        <Button
          className="mx-2"
          onClick={() => {
            setAddUserModal(true);
          }}
        >
          Add Priest
        </Button>
        <Button onClick={() => setAddParishUser(true)}>Add User</Button>
      </div>
      <Col xs="12" sm>
        <Accordion className="accordion-light">
          {users.map((user, index) => (
            <Accordion.Item key={index} eventKey={index}>
              <Accordion.Header>
                <div
                  style={{ width: "100%" }}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <span className="me-3 fw-bold text-primary">
                      {index + 1}
                    </span>
                    <div className="media-music">
                      <Link to="" className="media-img">
                        <img src={img29} alt="" />
                      </Link>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <h6 className="fw-bold text-primary mb-0">
                            {user.firstName} {user?.lastName}
                          </h6>
                          <Badge
                            className="text-white ms-2 fw-bold"
                            pill
                            bg="primary"
                          >
                            ADMIN
                          </Badge>
                        </div>
                        <span>{user.phoneNum}</span>
                        <span>{user.userEmail}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => {}}
                      variant="warning"
                      className="me-2"
                    >
                      <i className="ri-edit-line"></i>
                    </Button>
                    <Button
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#7e7e7e",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteParishUser(user?.userId);
                          }
                        });
                      }}
                      variant="danger"
                      className="me-2"
                    >
                      <i className="ri-delete-bin-6-line"></i>
                    </Button>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="mb-2">
                  <strong>This is the first item's accordion body.</strong>
                </p>
                <p className="mb-0">
                  It is shown by default, until the collapse plugin adds the
                  appropriate classes that we use to style each element. These
                  classes control the overall appearance, as well as the showing
                  and hiding via CSS transitions. You can modify any of this
                  with custom CSS or overriding our default variables.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>

      <AddParishUsersModal
        show={addUserModal}
        setShow={setAddUserModal}
        churchId={churchId}
        callGetAllParishUsers={callGetAllParishUsers}
      />
      <AddNonPriestUserModal
        show={addParishUser}
        setShow={setAddParishUser}
        callGetAllParishUsers={callGetAllParishUsers}
        churchId={churchId}
      />
    </Row>
  );
};

export default withAPIRequest(ParishUsers);
