import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import * as Yup from "yup";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ZonePlaceholder from "../../components/placeholders/ZonePlaceholder";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";

const alphabetsOnly = /^[a-zA-Z. ,]*$/;

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed.")
    .min(3),
});

const LinkOutsiderBaptism = ({ show, setShow, commonAPIRequest }) => {
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchUsersApi(fullNameArray[0], "")
        : callSearchUsersApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  const callSearchUsersApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${API.SEARCH_MEMBER}?firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }&filterBy=All`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          console.log("resulttttt---->", result);
          setMembers(
            result.payload.searchSuggestions.length !== 0
              ? result.payload.searchSuggestions
              : []
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-primary fw-bold">
          Link Baptism Certificate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-5">
          <Col xl>
            <div className="form-search  ">
              <i className="ri-search-line"></i>
              <Form.Control
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                type="text"
                placeholder="e.g. Joseph George"
              />
              <Button onClick={formik.handleSubmit} variant="primary">
                Search
              </Button>
            </div>
            <div className="mb-2">
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger">*{formik.errors.name}</p>
              )}
            </div>
          </Col>
          <p className="m-0 text-primary fw-bold" style={{ fontSize: 12 }}>
            Search the member for whom you want to link the certificate to
          </p>
        </Row>

        {/*  */}
        <Row className="g-2 g-xxl-3 mb-5">
          {loading && <ZonePlaceholder />}
          {members.map((member, index) => (
            <Col lg={6} key={index}>
              {
                <Card className="card-people shadow-smmd">
                  <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="mb-4"
                    >
                      <Avatar
                        // initial={`${member?.firstName
                        //   .charAt(0)
                        //   .toUpperCase()} ${member?.lastName
                        //   .charAt(0)
                        //   .toUpperCase()}`}
                        img={member.gender === "Female" ? femaleImg : maleImg}
                        size="xl"
                      />
                      <div className="ms-3">
                        <div className="mt-3 d-flex align-items-center">
                          <h6 className="fw-bold text-primary">
                            {member.middleName
                              ? `${member.firstName} ${member.middleName} ${member.lastName}`
                              : `${member.firstName} ${member.lastName}`}
                          </h6>
                          {member?.active ? (
                            <Badge className="ms-1 fw-bold" bg="success" pill>
                              Active
                            </Badge>
                          ) : (
                            <Badge className="ms-1 fw-bold" bg="danger" pill>
                              Inactive
                            </Badge>
                          )}
                        </div>
                        <p className="m-0 fw-bold text-primary">
                          {member.churchFamilyId}
                        </p>
                        <p className="m-0">
                          {member?.emailAddress ? member?.emailAddress : "-"}
                        </p>
                        {member?.familyHead ? (
                          <Badge pill bg="primary">
                            Head Of Family
                          </Badge>
                        ) : (
                          <Badge pill bg="warning">
                            Member
                          </Badge>
                        )}
                      </div>
                    </div>

                    <div className="d-grid">
                      <Button>Connect</Button>
                    </div>
                  </Card.Body>
                </Card>
              }
            </Col>
          ))}
          {!loading && members.length === 0 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="text-center mt-5"
              >
                <Lottie
                  style={{ width: 180, height: 180 }}
                  animationData={noSearchAnimation}
                  loop={true}
                />
              </div>
              <h3 className="text-center fw-bold">No Parishioner Found</h3>
              <h5 className="text-center fw-400">
                Please Try With Another Name.
              </h5>
            </div>
          )}
        </Row>
        {/*  */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary">Understood</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withAPIRequest(LinkOutsiderBaptism);
