import { Button, Card, Col, Badge, Row, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ChooseBaptismTypeModal from "../../modals/baptism/ChooseBaptismTypeModal";
import AddExternalBaptismDetailsModal from "../../modals/baptism/AddExternalBaptismDetailsModal";
import AddBaptismDetailsModal from "../../modals/baptism/AddBaptismDetailsModal";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import BaptismInfoOffset from "../../offset-canvas/baptism/BaptismInfoOffset";
import GeneratedBaptismCertificateModal from "../../modals/baptism/GeneratedBaptismCertificateModal";
import BaptismLinkCertificateModal from "../../modals/baptism/BaptismLinkCertificateModal";
import { useSelector } from "react-redux";

const BaptismDetails = ({
  baptismDetails,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
  commonAPIRequest,
  deathDetails,
}) => {
  const [baptismDetailModalVisible, setBaptismDetailModalVisible] =
    useState(false);
  const [extBaptismModalVisible, setExtBaptismModalVisible] = useState(false);
  const [baptismModalVisible, setBaptismModalVisible] = useState(false);
  const [certPdf, setCertPdf] = useState();
  const [loading, setLoading] = useState(false);
  const [baptismOffsetModalVisible, setBaptismOffsetModalVisible] =
    useState(false);
  const { churchDetails } = useSelector((state) => state?.church);
  const [generatedBaptismModal, setGeneratedBaptismModal] = useState(false);
  const [baptismLinkCertModal, setBaptismLinkCertModal] = useState(false);
  const [currentBaptism, setCurrentBaptism] = useState();
  const [loading2, setLoading2] = useState(false);

  // call generate pdf certificate api for baptism start

  const callGetBaptismCertificateApi = () => {
    let serviceParams = {
      api: `${API.GENERATE_BAPTISM_CERTIFICATE}?baptismId=${baptismDetails?.baptismId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCertPdf(result?.payload);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call generate pdf certificate api for baptism end

  // call generate pdf certificate api for baptism end

  const callGetBaptismDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
          setBaptismLinkCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetBaptismDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
          setGeneratedBaptismModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      {baptismDetails ? (
        <Card className="card-post mt-4 ">
          <Card.Header>
            <h3 className="fw-bold text-primary">Baptism Information</h3>
            <Button onClick={() => setBaptismOffsetModalVisible(true)}>
              <i className="ri-information-line"></i>
            </Button>
          </Card.Header>
          <hr className="hr m-0" />
          <Card.Body>
            <Row>
              <Col>
                <Row>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Baptism Done</strong>
                      </p>
                      <p className="mb-0">Yes</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>Date Of Baptism</strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-start">
                        <p className="mb-0">
                          {new Date(
                            baptismDetails?.baptismDate
                          ).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </p>
                        {!baptismDetails?.baptismDateAccurate && (
                          <Badge
                            style={{ padding: 5 }}
                            className="ms-2 fw-bold"
                            bg="warning"
                          >
                            Uncertain
                          </Badge>
                        )}
                      </div>
                    </div>
                  </Col>
                  {!baptismDetails?.extBaptism && (
                    <Col sm={4}>
                      <div className="my-2">
                        <p className="mb-0">
                          <strong> Baptism Certificate ID</strong>
                        </p>
                        <p className="mb-0">{baptismDetails?.referenceNum}</p>
                      </div>
                    </Col>
                  )}
                  <Col sm={4}>
                    <div className="my-2">
                      <p className="mb-0">
                        <strong>External Baptism</strong>
                      </p>
                      <p className="mb-0">
                        {baptismDetails?.extBaptism
                          ? baptismDetails?.extBaptismChurch ||
                            baptismDetails?.extBaptismCity
                            ? "Yes"
                            : "Not Sure"
                          : "No"}
                      </p>
                    </div>
                  </Col>
                  {baptismDetails?.extBaptism &&
                    baptismDetails?.extBaptismChurch && (
                      <Col sm={4}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>External Baptism Church</strong>
                          </p>
                          <p className="mb-0">
                            {baptismDetails?.extBaptismChurch}
                          </p>
                        </div>
                      </Col>
                    )}
                  {baptismDetails?.extBaptism &&
                    baptismDetails?.extBaptismCity && (
                      <Col sm={4}>
                        <div className="my-2">
                          <p className="mb-0">
                            <strong>External Baptism City</strong>
                          </p>
                          <p className="mb-0">
                            {baptismDetails?.extBaptismCity}
                          </p>
                        </div>
                      </Col>
                    )}
                </Row>
                {!baptismDetails?.extBaptism && (
                  <div>
                    <hr className="hr" />
                    <div className="text-center m-4">
                      <Card.Title className="text-dark">
                        Generate Baptism Certificate for{" "}
                        {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                        {basicDetails?.lastName}
                      </Card.Title>
                      <Card.Text>
                        Please click on Generate for generating baptism
                        certificate copy for{" "}
                        <span className="fw-bold text-primary">
                          {basicDetails?.firstName} {basicDetails?.middleName}{" "}
                          {basicDetails?.lastName}
                        </span>
                      </Card.Text>

                      <div>
                        <Button
                          disabled={baptismDetails?.extBaptism}
                          variant="primary"
                          onClick={() => {
                            callGetBaptismDetailsForLink(
                              baptismDetails?.baptismId
                            );
                          }}
                        >
                          {loading2 ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                                size="sm"
                              />
                              Generating...
                            </span>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center">
                              <i className="ri-download-2-fill me-2"></i>
                              Generate Link
                            </div>
                          )}
                        </Button>

                        {/* <Button
                          variant="primary"
                          className="ms-2"
                          onClick={() => {
                            callGetBaptismDetailsForPreview(
                              baptismDetails?.baptismId
                            );
                          }}
                        >
                          {loading ? (
                            <span className="d-flex align-items-center">
                              <Spinner
                                className="me-2"
                                animation="border"
                                variant="light"
                              />
                              Generating...
                            </span>
                          ) : (
                            <i className="ri-eye-line"></i>
                          )}
                        </Button> */}
                      </div>
                      {/* {loading ? (
                        <div className="justify-content-center align-items-center d-flex">
                          <Lottie
                            style={{ width: 100, height: 100 }}
                            animationData={loadingAnimation}
                            loop={true}
                          />
                        </div>
                      ) : certPdf?.certName ? (
                        <Button
                          onClick={() => {
                            let pdfWindow = window.open("");
                            pdfWindow.document.write(
                              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                encodeURI(certPdf.base64) +
                                "'></iframe>"
                            );
                          }}
                          variant="success"
                        >
                          Print
                        </Button>
                      ) : (
                        <Button
                          onClick={callGetBaptismCertificateApi}
                          variant="primary"
                        >
                          Generate
                        </Button>
                      )} */}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
          {
            <Card.Footer className="bg-light text-primary text-end">
              Last Updated by{" "}
              <span className="fw-bold">{baptismDetails?.updatedBy}</span> on
              <span className="fw-bold">
                {" "}
                {new Date(baptismDetails?.updateTmStmp).toLocaleDateString(
                  "en-GB",
                  {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }
                )}
              </span>
            </Card.Footer>
          }
        </Card>
      ) : (
        <Card.Body className="text-center m-4">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Lottie
                style={{ width: 150, height: 150 }}
                animationData={noSearchAnimation}
                loop={true}
              />
            </div>
          </div>
          <Card.Title className="text-dark">
            No Baptism Details Found
          </Card.Title>
          <Card.Text>
            Please add baptism details for{" "}
            <span className="fw-bold text-primary">
              {basicDetails?.firstName} {basicDetails?.middleName}{" "}
              {basicDetails?.lastName}
            </span>{" "}
            and generate certificate.
          </Card.Text>
          <Button
            onClick={() => setBaptismDetailModalVisible(true)}
            disabled={deathDetails ? true : false}
            variant="primary"
          >
            Add
          </Button>
        </Card.Body>
      )}
      <ChooseBaptismTypeModal
        show={baptismDetailModalVisible}
        setShow={setBaptismDetailModalVisible}
        setExtBaptismModalVisible={setExtBaptismModalVisible}
        setBaptismModalVisible={setBaptismModalVisible}
        basicDetails={basicDetails}
      />
      <AddExternalBaptismDetailsModal
        show={extBaptismModalVisible}
        setShow={setExtBaptismModalVisible}
        basicDetails={basicDetails}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
      <AddBaptismDetailsModal
        show={baptismModalVisible}
        setShow={setBaptismModalVisible}
        basicDetails={basicDetails}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />
      <BaptismInfoOffset
        show={baptismOffsetModalVisible}
        setShow={setBaptismOffsetModalVisible}
        baptismId={baptismDetails?.baptismId}
        basicDetails={basicDetails}
        memberId={memberId}
        getFamilyMemberDetails={getFamilyMemberDetails}
      />

      <GeneratedBaptismCertificateModal
        setShow={setGeneratedBaptismModal}
        show={generatedBaptismModal}
        baptismId={baptismDetails?.baptismId}
        churchDetails={churchDetails}
        currentBaptism={currentBaptism}
      />
      <BaptismLinkCertificateModal
        show={baptismLinkCertModal}
        setShow={setBaptismLinkCertModal}
        baptismId={baptismDetails?.baptismId}
        churchDetails={churchDetails}
        currentBaptism={currentBaptism}
      />
    </div>
  );
};

export default withAPIRequest(BaptismDetails);
