export const BASE_URL = "https://trial.resource.service.oneintrinity.com";
export const BASE_URL_AUTH = "https://trial.oauth.service.oneintrinity.com";

export const API = {
  // -------------------------------------------------------------
  // ZONE CONTROLLER
  ADD_ZONE: `${BASE_URL}/resource/server/zone/detail`,
  GET_ALL_ZONES: `${BASE_URL}/resource/server/zone/detail/all`,
  UPDATE_ZONE: `${BASE_URL}/resource/server/zone/detail`,
  ADD_SUB_ZONE: `${BASE_URL}/resource/server/zone/subzone/detail`,
  GET_ALL_SUB_ZONES: `${BASE_URL}/resource/server/zone/subzone/detail`,
  UPDATE_SUB_ZONES: `${BASE_URL}/resource/server/zone/subzone/detail`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // CSF CONTROLLER
  ADD_DONATION_CSF: `${BASE_URL}/resource/server/support/fund/detail`,
  DONATION_STATUS_CSF: `${BASE_URL}/resource/server/support/fund/status`,
  GET_FAMILY_DONATION_CSF: `${BASE_URL}/resource/server/support/fund/family/summary`,
  GET_CSF_SUMMARY: `${BASE_URL}/resource/server/support/fund/summary`,
  GET_CSF_GRAPH_SUMMARY: `${BASE_URL}/resource/server/support/fund/detail/graph/summary`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // FAMILY CONTROLLER

  CREATE_FAMILY: `${BASE_URL}/resource/server/member/detail`,
  GET_ALL_FAMILY: `${BASE_URL}/resource/server/member/heads/summary`,
  GET_ALL_FAMILY_MEMBERS: `${BASE_URL}/resource/server/family/member/summary`,
  GET_FAMILY_DETAILS: `${BASE_URL}/resource/server/family/detail`,
  CREATE_MEMBER: `${BASE_URL}/resource/server/member/detail`,
  GET_MEMBER_DETAIL: `${BASE_URL}/resource/server/member/detail`,
  GET_RELATION: `${BASE_URL}/resource/server/family/relations/all`,
  UPDATE_MEMBER: `${BASE_URL}/resource/server/member/detail`,
  SEARCH_MEMBER: `${BASE_URL}/resource/server/member/search`,
  TRANSFER_MEMBER: `${BASE_URL}/resource/server/member/transfer`,
  TRANSFER_CREATE_NEW_FAMILY: `${BASE_URL}/resource/server/family/detail`,
  CHANGE_HEAD_OF_FAMILY: `${BASE_URL}/resource/server/family/head`,
  DELETE_MEMBER: `${BASE_URL}/resource/server/member/detail`,

  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // BAPTISM APIS
  EXTERNAL_BAPTISM_API: `${BASE_URL}/resource/server/baptism/detail`,
  GENERATE_BAPTISM_CERTIFICATE: `${BASE_URL}/resource/server/baptism/certificate`,
  BAPTISM_LIST: `${BASE_URL}/resource/server/baptism/detail/summary`,
  SEARCH_BAPTISM: `${BASE_URL}/resource/server/baptism/detail/search`,
  BAPTISM_DETAILS: `${BASE_URL}/resource/server/baptism/detail`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // HOLY COMMUNION APIS
  EXTERNAL_HOLY_COMMUNION_API: `${BASE_URL}/resource/server/holy/communion/detail`,
  GENERATE_HOLY_COMMUNION_CERTIFICATE: `${BASE_URL}/resource/server/holy/communion/certificate`,
  HOLY_COMMUNION_LIST: `${BASE_URL}/resource/server/holy/communion/detail/summary`,
  SEARCH_HOLY_COMMUNION: `${BASE_URL}/resource/server/holy/communion/detail/search`,
  HOLY_COMMUNION_DETAILS: `${BASE_URL}/resource/server/holy/communion/detail
`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // HOLY Confirmation APIS
  EXTERNAL_HOLY_CONFIRMATION_API: `${BASE_URL}/resource/server/confirmation/detail`,
  GENERATE_HOLY_CONFIRMATION_CERTIFICATE: `${BASE_URL}/resource/server/confirmation/certificate`,
  HOLY_CONFIRMATION_LIST: `${BASE_URL}/resource/server/confirmation/detail/summary`,
  SEARCH_HOLY_CONFIRMATION: `${BASE_URL}/resource/server/confirmation/detail/search`,
  HOLY_CONFIRMATION_DETAILS: `${BASE_URL}/resource/server/confirmation/detail`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // Holy Order Apis
  ADD_HOLY_ORDER: `${BASE_URL}/resource/server/holy/order/detail`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // Demise Details API
  ADD_DEMISE_DETAILS: `${BASE_URL}/resource/server/death/detail`,
  GENERATE_BURIAL_LETTER: `${BASE_URL}/resource/server/letter/burial`,
  GENERATE_BURIAL_CERTIFICATE: `${BASE_URL}/resource/server/certificate/burial`,

  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // MARRIAGE APIS
  EXTERNAL_MARRIAGE_API: `${BASE_URL}/resource/server/marriage/detail`,
  GENERATE_MARRIAGE_CERTIFICATE: `${BASE_URL}/resource/server/marriage/certificate`,
  MARRIAGE_LIST: `${BASE_URL}/resource/server/marriage/detail/summary`,
  SEARCH_MARRIAGE_DETAILS: `${BASE_URL}/resource/server/marriage/detail/search`,
  MARRIAGE_DETAILS: `${BASE_URL}/resource/server/marriage/detail`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // ANNULMENT APIs
  EXTERNAL_ANNULMENT_API: `${BASE_URL}/resource/server/annulment/detail`,
  GENERATE_ANNULMENT_CERTIFICATE_API: `${BASE_URL}/resource/server/annulment/certificate`,
  ANNULMENT_LIST: `${BASE_URL}/resource/server/annulment/detail/summary`,
  SEARCH_ANNULMENT_DETAILS: `${BASE_URL}/resource/server/annulment/detail/search`,
  ANNULMENT_DETAILS: `${BASE_URL}/resource/server/annulment/detail`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // CHURCH API

  GET_CHURCH: `${BASE_URL}/resource/server/user/church`,

  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // SETTINGS API
  UPDATE_PASSWORD: `${BASE_URL_AUTH}/auth/server/user/update/password`,
  GET_USER_INFO: `${BASE_URL}/resource/server/user`,
  UPDATE_USER_INFO: `${BASE_URL}/resource/server/user`,
  DELETE_USER_INFO: `${BASE_URL}/resource/server/user`,

  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // CHURCH EXPENSE API

  ADD_EXPENSE: `${BASE_URL}/resource/server/expense/detail`,
  EXPENSE_SUMMARY: `${BASE_URL}/resource/server/expense/detail/summary`,
  GET_EXPENSE_GRAPH_SUMMARY: `${BASE_URL}/resource/server/expense/detail/graph/summary`,

  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // CHURCH DONATION API

  ADD_DONATION: `${BASE_URL}/resource/server/donation/detail`,
  DONATION_SUMMARY: `${BASE_URL}/resource/server/donation/detail/summary`,
  GET_DONATION_GRAPH_SUMMARY: `${BASE_URL}/resource/server/donation/detail/graph/summary`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // DIOCESE DONATION API

  ADD_DIOCESE_DONATION: `${BASE_URL}/resource/server/diocesan/donation`,
  DIOCESE_DONATION_SUMMARY: `${BASE_URL}/resource/server/diocesan/donation/summary`,
  // GET_DIOCESE_DONATION_GRAPH_SUMMARY: `${BASE_URL}/resource/server/donation/detail/graph/summary`,
  GET_DIOCESE_DONATION_GRAPH_SUMMARY: `${BASE_URL}/resource/server/diocesan/donation/graph/summary`,
  // -------------------------------------------------------------

  // -------------------------------------------------------------
  // CHURCH Dashboard API

  GET_BIRTHDAY: `${BASE_URL}/resource/server/dashboard/birthday/summary`,
  DASHBOARD_DATA: `${BASE_URL}/resource/server/dashboard`,

  // -------------------------------------------------------------

  // CHURCH CONTROLLER RESOURCE SERVER
  GET_ALL_CHURCH_RESOURCE: `${BASE_URL}/resource/server/church/summary`,
  CREATE_CHURCH_RESOURCE: `${BASE_URL}/resource/server/church/create`,
  GET_CHURCH_DETAIL_RESOURCE: `${BASE_URL}/resource/server/church/detail`,
  UPDATE_CHURCH_DETAILS_RESOURCE: `${BASE_URL}/resource/server/church/`,
  CHURCH_IMAGE: `${BASE_URL}/resource/server/church/image`,
  CHURCH_ENABLE: `${BASE_URL}/resource/server/church/enable`,
  CHURCH_DISABLE: `${BASE_URL}/resource/server/church/disable`,
  CHURCH_DASHBOARD: `${BASE_URL}/resource/server/dashboard/church`,

  // CHURCH CONTROLLER AUTH SERVER
  GET_ALL_USERS_RESOURCE: `${BASE_URL}/resource/server/user/summary`,
  CREATE_USER_RESOURCE: `${BASE_URL}/resource/server/user`,

  // PRIEST DIRECTORY API
  GET_PRIEST_SUMMARY: `${BASE_URL}/resource/server/priest/summary`,
  SEARCH_PRIEST_SUMMARY: `${BASE_URL}/resource/server/priest/detail/search`,
  CREATE_NEW_PRIEST: `${BASE_URL}/resource/server/priest/detail`,
  UPDATE_PRIEST_DETAILS: `${BASE_URL}/resource/server/priest/detail`,
  GET_PRIEST_DETAILS: `${BASE_URL}/resource/server/priest/detail`,
  PRIEST_IMAGE: `${BASE_URL}/resource/server/priest/image`,
  // -------------------------------------------------------------
};

export const AXIOS_METHOD_TYPES = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};
